<template>
  <b-overlay
    :show="isLoading"
    variant="light"
    :opacity="0.75"
    blur="2px"
    centered
    style="min-height: 10rem"
  >
    <div v-if="recordData">
      <b-row>
        <b-col cols="12">
          <div class="mb-3">
            <div
              class="h3 d-flex flex-column flex-md-row align-items-md-center justify-content-between border-bottom"
            >
              <span
                class="border-bottom-primary"
                style="border-bottom-width: 4px; padding-bottom: 4px"
              >
                {{
                $t("lbl_orders_detail") }}
              </span>
              <span>#{{ recordData.order_code }}</span>
            </div>
            <StepLine
              :steps="order_statuses"
              :currentStatus="recordData.delivery_material_status"
              :orderId="recordData.id"
              :historyCanceled="historyCanceled"
              @openModalExport="openModalExport"
              @openModalUploadFile="handleOpenModalUploadFile"
              @refresh="changeStatus()"
              @viewConfirmImage="handleOpenModalUploadFile"
              :detailDelivery="detailDelivery"
            />
            <div class="pt-1">
              <ProductItem
                v-for="(product, index) in orderItems"
                :class="index < recordData.order_items.length - 1
                  ? 'mb-1 pb-1 border-bottom'
                  : ''
                "
                :key="product.id"
                :product="product.data"
                :orderData="recordData"
                :ordersId="recordData.id"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="8" class="border-right" style="margin-right: -1px">
          <div>
            <div class="h3 d-flex align-items-center justify-content-between border-bottom">
              <span
                class="border-bottom-primary"
                style="border-bottom-width: 4px; padding-bottom: 4px"
              >
                {{
                $t("lbl_customer_info") }}
              </span>
            </div>
            <!-- <div class="pt-1">
              <div
                class="border-left-primary font-weight-bolder h5 mb-1"
                style="padding: 4px 0 4px 4px; border-left-width: 4px;"
              >{{ $t('lbl_orderer') }}</div>
              <ReceiverInfo :data="handleGetDataReceiver(1)" />
            </div>-->
            <!-- <div>
            <b-form-checkbox
              v-model="record.isPersonReceiver"
              :disabled="!isEdit"
              class="mb-2 mr-sm-2 mb-sm-0"
            >Use this information as the receiver</b-form-checkbox>
            </div>-->
            <div class="pt-1" v-if="handleGetDataReceiver(2) && handleGetDataReceiver(2).name">
              <div
                class="border-left-primary font-weight-bolder h5 mb-1"
                style="padding: 4px 0 4px 4px; border-left-width: 4px"
              >{{ $t("lbl_receiver") }}</div>
              <ReceiverInfo :data="handleGetDataReceiver(2)" />
              <div
                class="border-left-primary font-weight-bolder h5 mb-1"
                style="padding: 4px 0 4px 4px; border-left-width: 4px"
                v-if="recordData && recordData.product_order && recordData.product_order.notes"
              >{{ $t("lbl_notes") }}</div>
              <div>{{recordData.product_order.notes}}</div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="4" xxl="4" class="border-left">
          <b-card no-body>
            <h3 class="block p-1 mb-0">
              <span>Thông tin thanh toán</span>
            </h3>
            <!-- Thông tin tổng giá trị đơn hàng -->
            <div class="px-1 pb-1 flex items-center justify-between">
              <div>
                <span>Mã phiếu thu</span>
                <div
                  class="text-base font-bold mb-0 h5"
                >#{{ recordData.slip && recordData.slip.number_code }}</div>
              </div>
              <b-button variant="outline-primary" @click="handleShowDetailIndication">Xem phiếu thu</b-button>
            </div>

            <div class="px-1 pb-1">
              <PaymentMethods :data="(recordData && recordData.payment) || {}" />
              <!-- SELECT -->
              <div class="d-flex flex-column">
                <!-- <span>{{ $t("lbl_status") }}</span> -->
                <div class="d-flex flex-1 justify-content-end">
                  <div
                    v-click-outside="handleCloseDropDown"
                    class="cs-relative"
                    style="width: 100%"
                  >
                    <div
                      @click="handleToggleDropdown"
                      class="d-flex align-items-center justify-content-center"
                      :class="{
                      'not-payment cursor-pointer justify-content-between':
                        paymentInfo.value === PAYMENT_TYPE[0].value,
                      payment: paymentInfo.value === PAYMENT_TYPE[1].value,
                      status: true,
                    }"
                    >
                      <div>{{ paymentInfo.label }}</div>
                      <feather-icon
                        v-if="
                        paymentInfo.value === PAYMENT_TYPE[0].value &&
                        recordData.slip &&
                        recordData.slip.owner_type !==
                        TRANSACTION_SLIP_TYPE.Ws
                      "
                        icon="ChevronDownIcon"
                        size="20"
                        class="text-white ml-1"
                      />
                    </div>
                    <div v-if="isOpenDropdown" class="menu-select">
                      <div
                        @click="handleSelectPaymentType(item)"
                        class="cs-menu-item"
                        :class="{
                        'cs-item-active': item.value === paymentInfo.value,
                      }"
                        v-for="item in PAYMENT_TYPE"
                        :key="item.value"
                      >
                        <span>{{ item.label }}</span>
                        <feather-icon
                          v-if="item.value === paymentInfo.value"
                          icon="CheckIcon"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-1">
              <div
                :key="index"
                v-for="(item, index) in recordData.order_items"
                class="flex flex-col md:flex-row gap-1 mb-1"
              >
                <div
                  @click="handleRedirectOrder(item)"
                  class="text-blue-900 font-bold"
                  :class="{
                  'cursor-pointer cs-text':
                    item.data && item.data.type === PRODUCT_TYPES.SERVICE,
                }"
                >{{ item.data.product_name }}</div>
                <div class="flex justify-between items-center">
                  <div>{{ $t("Thành tiền") }}</div>
                  <div class="text-right">
                    {{ item.data.qty }}
                    x
                    {{ appUtils.numberFormat(item.data.price, "VNĐ") }}
                  </div>
                </div>
                <div
                  v-if="item.data.campaign_info && item.data.campaign_info.code"
                  class="flex justify-between items-center"
                >
                  <div>{{ $t("Giảm giá") }}</div>
                  <div>-{{ getDiscountPrice(item.data) }}</div>
                </div>
              </div>
            </div>
            <div class="px-1 pb-1 flex justify-between items-center gap-2">
              <h4>Tổng tiền</h4>
              <div class="text-primary cs-bg p-1 h3 mb-0">{{ getTotalPriceRemaining(recordData) }}</div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="hodo-modal-scrollable"
      size="xl"
      scrollable
      hide-header
      ok-only
      :ok-title="$t('lbl_close')"
      v-model="showModalExport"
    >
      <ModalExport :recordData="recordData" @mapLot="mapLot" @refresh="changeStatus()" />
    </b-modal>
    <b-modal
      id="modal-upload-file-confirm"
      modal-class="modal-upload-file-confirm"
      size="lg"
      title="Thông tin giao hàng"
      hide-footer
      v-model="showModalUploadFile"
    >
      <ModalUploadFileConfirm
        :orderId="recordData.order_id"
        :dataDelivery="detailDelivery"
        @refresh="changeStatus"
        @save="handleViewDetailDelivery(recordData.order_id)"
      ></ModalUploadFileConfirm>
    </b-modal>
    <!-- <b-modal
      id="hodo-modal-scrollable"
      size="lg"
      scrollable
      hide-header
      ok-only
      :ok-title="$t('lbl_close')"
      v-model="showModalDetailIndication"
    >
      <ModalDetailIndication />
    </b-modal>-->
  </b-overlay>
</template>

<script>
import i18n from '@/libs/i18n'
import store from '@/store'
import EventBus from '@/utils/EventBus'
import appUtils from '@/utils/appUtils'
import {
  ORDERS_STATUS,
  ORDER_DELIVERY_STATES,
  PAYMENT_METHOD,
  PAYMENT_TYPE,
  PRODUCT_TYPES,
  DELIVERY_CONFIRM_STATUS,
  TRANSACTION_SLIP_TYPE
} from '@/utils/constant'
import PaymentMethods from '@/views/Orders/Detail/Components/PaymentMethods.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCol, BRow } from 'bootstrap-vue'
import ModalExport from './Components/ModalExport.vue'
import ModalUploadFileConfirm from './Components/ModalUploadFileConfirm.vue'
import ProductItem from './Components/ProductItem.vue'
import ReceiverInfo from './Components/ReceiverInfo.vue'
import StepLine from './Components/StepLine.vue'
// import ModalDetailIndication from './Components/ModalDetailIndication.vue'

const paymentStatusOptions = [
  {
    label: i18n.t('obj_payment_status.not_payment'),
    value: 1
  },
  {
    label: i18n.t('obj_payment_status.paid'),
    value: 2
  }
  // {
  //   label: i18n.t("obj_payment_status.canceled"),
  //   value: 3
  // }
]
export default {
  name: 'OrdersMarketPlaceDetail',
  components: {
    BRow,
    BCol,
    StepLine,
    ProductItem,
    ReceiverInfo,
    // OrdersStatus,
    PaymentMethods,
    // Definition
    ModalExport,
    ModalUploadFileConfirm
    // ModalDetailIndication
  },
  props: {
    // recordData: {
    //   type: Object,
    //   required: true
    // },
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isOpenDropdown: false,
      payment_status: {},
      paymentStatusOptions,
      appUtils,
      ORDERS_STATUS,
      ordererForm: {},
      receiverForm: {},
      recordData: {
        order_items: []
      },
      show: true,
      isEdit: false,
      showModalExport: false,
      PAYMENT_METHOD,
      PAYMENT_TYPE,
      order_statuses: [...ORDER_DELIVERY_STATES],
      isLoading: false,
      paymentInfo: {},
      TRANSACTION_SLIP_TYPE,
      PRODUCT_TYPES,
      showModalDetailIndication: false,
      showModalUploadFile: false,
      detailDelivery: {}
    }
  },
  computed: {
    historyCanceled() {
      return {
        reject_reason: this.recordData.reject_reason,
        ...this.recordData?.supplier_order_delivery_history?.find(
          elm => elm.to_state === 100
        )
      }
    },
    payInfo() {
      if (this.recordData.payment_method === PAYMENT_METHOD.points) {
        const time = this.recordData?.org_service_transaction?.updated_at
          ? appUtils.formatDateTime(
              this.recordData?.org_service_transaction?.updated_at,
              'HH:mm DD/MM/YYYY'
            )
          : ''
        const name = this.recordData?.pay_by_infor?.name

        return {
          time,
          name
        }
      } else {
        const time = this.recordData?.payment_status_updated_at
          ? appUtils.formatDateTime(
              this.recordData?.payment_status_updated_at,
              'HH:mm DD/MM/YYYY'
            )
          : ''
        const name = this.recordData?.updated_by_infor?.name

        return {
          time,
          name
        }
      }
    },
    orderItems() {
      const newOrderItems = (this.recordData?.order_items || []).filter(
        item => item.data?.type === PRODUCT_TYPES.PRODUCT
      )
      return newOrderItems
    }
  },
  watch: {
    orderId: {
      async handler(data) {
        if (!data) {
          return
        }
        this.order_statuses = [...ORDER_DELIVERY_STATES]
        await this.viewDetailOrders()
        this.handleViewDetailDelivery(this.recordData?.order_id)
        const foundPaymentStatus = this.paymentStatusOptions.find(
          x => x.value === this.recordData.payment?.status
        )
        if (foundPaymentStatus) {
          this.payment_status = foundPaymentStatus
        }
      },
      deep: true,
      immediate: true
    },
    recordData: {
      handler(data) {
        this.recordData = data || {
          order_items: []
        }
      },
      deep: true,
      immediate: true
    },
    'recordData.payment': {
      deep: true,
      handler(data) {
        const foundPaymentStatus = this.paymentStatusOptions.find(
          x => x.value === data?.status
        )
        if (foundPaymentStatus) {
          this.payment_status = foundPaymentStatus
        }
      }
    },
    showModalDetailIndication: {
      handler(value) {
        if (value === false) {
          this.$router.push({ query: null })
        }
      }
    }
  },
  // mounted() {
  //   this.viewDetailOrders()
  // },
  methods: {
    handleCloseDropDown() {
      this.isOpenDropdown = false
    },
    async handleSelectPaymentType(option) {
      if (option.value === PAYMENT_TYPE[1].value) {
        this.$bvModal
          .msgBoxConfirm(
            'Bạn có chắc chắn muốn cập nhật trạng thái sang đã thanh toán không?',
            {
              title: 'Xác nhận trạng thái',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Xác nhận',
              cancelTitle: 'Huỷ',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true
            }
          )
          .then(value => {
            if (value) {
              // this.paymentInfo = option;
              this.handlePaymentReceipt()
              return
            } else {
              return
            }
          })
      }
    },
    // handleToggleDropdown() {
    //   if (
    //     this.paymentInfo.value === PAYMENT_TYPE[1].value ||
    //     this.recordData?.slip?.owner_type === TRANSACTION_SLIP_TYPE.Ws
    //   )
    //     return
    //   this.isOpenDropdown = !this.isOpenDropdown
    //   return appUtils.numberFormat(price < 0 ? 0 : price, 'VNĐ')
    // },
    async handleChangeStatus() {
      const params = {
        order_id: this.recordData.id,
        status: this.payment_status.value,
        tx_date: moment().valueOf()
      }
      const res = await store
        .dispatch('ecomOrder/changePaymentStatusOrder', params)
        .then(response => {
          this.changeStatus()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Cập nhật trạng thái thanh toán thành công'),
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('lbl_error_fetching_data'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
          return null
        })
      return res
    },
    handleToggleDropdown() {
      if (this.recordData.status === 100) return
      if (this.payment_status.value === PAYMENT_TYPE[0].value) {
        this.isOpenDropdown = !this.isOpenDropdown
      }
    },
    mapLot(data) {
      this.recordData.order_items.forEach((x, index) => {
        const founded = data?.find(
          i => i.product_variant_id == x.product_variant_id
        )
        if (founded) {
          const convertLots = founded.data.map(x => ({
            ...x,
            active: false
          }))
          this.recordData.order_items[index].lot = convertLots
        }
      })
    },
    openModalExport() {
      this.showModalExport = true
    },
    handleOpenModalUploadFile() {
      this.showModalUploadFile = true
    },
    changeStatus() {
      this.viewDetailOrders()
      EventBus.$emit('changeOrderStatus')
    },
    async viewDetailOrders() {
      const res = await store
        .dispatch('ecomOrder/fetchOrderById', {
          id: this.orderId
        })
        .then(response => {
          this.isLoading = false
          this.recordData = { ...response.data?.data } || {
            order_items: []
          }
          this.paymentInfo =
            PAYMENT_TYPE.find(
              item => item.value === this.recordData?.payment?.status
            ) || {}
          if (this.recordData.supplier_order_delivery_history?.length) {
            this.order_statuses.forEach((x, index) => {
              const foundStep = this.recordData.supplier_order_delivery_history?.find(
                i => i.to_state === x.order_number
              )
              if (foundStep) {
                if (x.date) {
                  if (foundStep.time_changed > x.date) {
                    this.order_statuses[index] = {
                      ...this.order_statuses[index],
                      updated_name: foundStep?.user_infor?.name,
                      date: foundStep.time_changed
                    }
                  }
                } else {
                  this.order_statuses[index] = {
                    ...this.order_statuses[index],
                    updated_name: foundStep?.user_infor?.name,
                    date: foundStep.time_changed
                  }
                }
              }
            })
          }
          this.showModalExport = false
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('lbl_error_fetching_data'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
          return null
        })
      return res
    },
    onSubmit(event) {
      event.preventDefault()
    },
    onReset(event) {
      event.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.isLoading = false
      this.$nextTick(() => {
        this.isLoading = true
      })
    },
    handleGetDataReceiver(type) {
      return (
        this.recordData?.order_contact?.find(item => item?.type === type) || {}
      )
    },
    getTotalPriceRemaining(item) {
      const price = item.slip?.amount

      return appUtils.numberFormat(price < 0 ? 0 : price, 'VNĐ')
    },
    async handlePaymentReceipt() {
      if (!this.recordData?.slip?.service_transaction_slip_code) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Không tìm thấy thông tin phiếu thu'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
        return
      }
      try {
        this.isLoading = true
        await store.dispatch('orders/paymentTransactionSlipOff', {
          tx_code: this.recordData?.slip?.service_transaction_slip_code
        })
        this.isOpenDropdown = false;

        await this.viewDetailOrders({ id: this.orderId })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Đổi trạng thái thanh toán thành công'),
            icon: 'CheckIcon',
            variant: 'success'
          }
        })
        this.isLoading = false
        this.$emit('success')
      } catch (error) {
        this.isLoading = false

        console.log(error)
      } finally {
        //
      }
    },
    handleRedirectOrder(item) {
      if (item.data.type === PRODUCT_TYPES.SERVICE) {
        this.$router.push({
          name: 'orderDetail',
          params: {
            id: this.recordData?.id
          }
        })
      }
    },
    handleShowDetailIndication() {
      // this.showModalDetailIndication = true
      this.$router.push({
        path: `/indication/${this.recordData.id}`
      })
    },
    getDiscountPrice(data) {
      return appUtils.numberFormat(
        data.campaign_info?.discount_amount || 0,
        'VNĐ'
      )
    },
    async handleViewDetailDelivery(order_id) {
      try {
        const res = await this.$rf
          .getRequest('CommonRequest')
          .getDetailDelivery(order_id)
        this.detailDelivery = res?.data
        return res
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  top: 120px;
  right: 0;
  z-index: 2;
  // width: 100%;
  margin: 50px 14px 0 0;
  border-radius: 6px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-menu-item {
  cursor: pointer;
  width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}

.cs-text {
  text-decoration: underline;
}
</style>
<style lang="scss">
.modal-upload-file-confirm {
  .modal-dialog {
    margin-top: 7rem;
  }
}
</style>
