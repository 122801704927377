import {
  ORDERS_PRODUCT_STATUS,
  ORDERS_STATUS,
  PRODUCT_TYPES,
} from "@/utils/constant";
import { computed, ref, watch } from "@vue/composition-api";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import appUtils from "@/utils/appUtils";
import i18n from "@/libs/i18n";
import store from "@/store";
import { useToast } from "vue-toastification/composition";

export default function useOrdersList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refOrdersTable = ref(null);

  // Table Handlers
  const tableColumns = computed(() => {
    const defaultCols = [
      { key: "order_code", label: i18n.t("lbl_code"), sortable: false },
      { key: "customer", label: i18n.t("lbl_customer"), sortable: false },
      { key: "order_date", label: i18n.t("lbl_order_date"), sortable: false },
      {
        key: "delivery_status",
        label: i18n.t("lbl_product_order_status"),
        sortable: false,
      },
      {
        key: "payment_status",
        label: i18n.t("lbl_payment_status"),
        sortable: false,
      },
      {
        key: "total_order_amount",
        label: i18n.t("Tổng giá trị (VNĐ)"),
        sortable: false,
      },
      {
        key: "transaction_point",
        label: i18n.t("H-Credit"),
        sortable: false,
      },
      {
        key: "transaction_campaign",
        label: i18n.t("Order"),
        sortable: false,
      },
      {
        key: "customer_pay",
        label: i18n.t("Khách hàng trả (VNĐ)"),
        sortable: false,
      },
      // {
      //   key: "slip.number_code",
      //   label: i18n.t("Mã phiếu thu"),
      //   sortable: false,
      // },
      { key: "action", label: "", thClass: "text-right" },
    ];
    return followMode.value
      ? [{ key: "selecting", label: "", sortable: false }, ...defaultCols]
      : defaultCols;
  });
  const statusFilterOptions = computed(() => [
    {
      value: null,
      text: i18n.t("lbl_all_status"),
    },
    {
      value: ORDERS_PRODUCT_STATUS.RECEIVE_ORDERS,
      text: i18n.t("obj_order_status.receive_order"),
    },
    {
      value: ORDERS_PRODUCT_STATUS.PROCESSING,
      // text: i18n.t("obj_order_status.processing"),
      text: "Đã xử lý",
    },
    {
      value: ORDERS_PRODUCT_STATUS.STOCK_OUT,
      text: i18n.t("obj_order_status.stock_out"),
    },
    {
      value: ORDERS_PRODUCT_STATUS.DELIVERY,
      text: i18n.t("obj_order_status.delivery"),
    },
    // {
    //   value: ORDERS_PRODUCT_STATUS.PAYMENT,
    //   text: i18n.t("obj_order_status.payment"),
    // },
    {
      value: ORDERS_PRODUCT_STATUS.FINISH,
      text: i18n.t("obj_order_status.finish"),
    },
    {
      value: ORDERS_PRODUCT_STATUS.CANCELED,
      text: i18n.t("obj_order_status.canceled"),
    },
  ]);
  const paymentStatusFilterOptions = computed(() => [
    {
      value: null,
      text: i18n.t("lbl_all_payment_status"),
    },
    {
      value: 1,
      text: i18n.t("obj_payment_status.not_payment"),
    },
    {
      value: 2,
      text: i18n.t("obj_payment_status.paid"),
    },
    // {
    //   value: 3,
    //   text: i18n.t("obj_payment_status.canceled"),
    // }
  ]);

  const perPage = ref(10);
  const totals = ref(0);
  const currentPage = ref(1);
  const searchQuery = ref("");
  const followMode = ref(""); // '', 'floating', 'follow'
  const sortBy = ref("order_id");
  const isSortDirDesc = ref(true);
  const outOfStockFilter = ref(null);
  const activeFilter = ref(null);
  const allOpenRows = ref([]);
  const recordDetail = ref(null);
  const showDetailOrder = ref(false);
  const statusFilter = ref(null);
  const paymentStatusFilter = ref(null);
  const fromDate = ref(null);
  const toDate = ref(null);
  const isLoading = ref(false);
  const listOrders = ref(null);

  if (store.state.orders.filterParams.supplier_id) {
    (searchQuery.value = store.state.orders.filterParams.keyword),
      (perPage.value = store.state.orders.filterParams.page_size),
      (currentPage.value = store.state.orders.filterParams.page_num),
      (statusFilter.value = store.state.orders.filterParams.status),
      (statusFilter.value = store.state.orders.filterParams.payment_status),
      (fromDate.value = store.state.orders.filterParams.from_date),
      (toDate.value = store.state.orders.filterParams.to_date);
    followMode.value = store.state.orders.filterParams.followMode;
  }

  const dataMeta = computed(() => {
    const localItemsCount = refOrdersTable.value
      ? refOrdersTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    };
  });

  const refetchData = () => {
    refOrdersTable.value.refresh();
  };

  // const fetchOrdersList = (ctx, callback) => {
  //   isLoading.value = true
  //   const userData = appUtils.getLocalUser()

  //   const params = {
  //     keyword: searchQuery.value,
  //     mode: followMode.value,
  //     page_size: perPage.value,
  //     page_num: currentPage.value,
  //     supplier_id: userData?.supplierInfo?.id,
  //     sort_by: "created_at",
  //     order: "desc",
  //     status: statusFilter.value,
  //     payment_status: paymentStatusFilter.value,
  //     from_date: fromDate.value ? fromDate.value.getTime() : fromDate.value,
  //     to_date: toDate.value ? moment(toDate.value).endOf('day').valueOf() : toDate.value,
  //   }

  //   store
  //     .dispatch("ecomOrder/fetchOrder", params)
  //     .then((response) => {
  //       const orders = response.data?.data
  //       totals.value = response.data?.page?.total
  //       callback(orders)
  //       isLoading.value = false
  //     })
  //     .catch((e) => {
  //       toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: i18n.t("lbl_error_fetching_data"),
  //           icon: "AlertTriangleIcon",
  //           variant: "danger",
  //         },
  //       })
  //     })
  // }
  const fetchOrdersList = (ctx, callback) => {
    isLoading.value = true;
    const userData = appUtils.getLocalUser();

    const params = {
      keyword: searchQuery.value,
      mode: followMode.value,
      page_size: perPage.value,
      page_num: currentPage.value,
      supplier_id: userData?.supplierInfo?.id,
      sort_by: "id",
      order: "desc",
      delivery_material_status: statusFilter.value,
      payment_status: paymentStatusFilter.value,
      from_date: fromDate.value ? fromDate.value.getTime() : fromDate.value,
      to_date: toDate.value
        ? moment(toDate.value).endOf("day").valueOf()
        : toDate.value,
      type: PRODUCT_TYPES.PRODUCT,
    };

    store
      .dispatch("orders/fetchOrdersList", params)
      .then((response) => {
        const orders = response.result?.data;
        totals.value = response.result?.page?.total;
        listOrders.value = orders;
        callback(orders);
        isLoading.value = false;
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case ORDERS_STATUS.RECEIVE_ORDERS:
        return {
          classes: "bg-primary text-white",
          icon: "LoaderIcon",
        };
      case ORDERS_STATUS.PROCESSING:
        return {
          classes: "bg-primary text-white",
          icon: "LoaderIcon",
        };
      case ORDERS_STATUS.SENDING:
        return {
          classes: "bg-primary text-white",
          icon: "LoaderIcon",
        };
      case ORDERS_STATUS.FINISHED:
        return {
          classes: "bg-success text-white",
          icon: "CheckIcon",
        };
      case ORDERS_STATUS.CANCELED:
        return {
          classes: "bg-danger text-white",
          icon: "XIcon",
        };
      case ORDERS_STATUS.WAITING_CONFIRM:
        return {
          classes: "bg-light",
          icon: "MoreHorizontalIcon",
        };
      case ORDERS_STATUS.ERROR_PAYMENT:
        return {
          classes: "bg-danger text-white",
          icon: "XIcon",
        };

      default:
        return {
          classes: "bg-white",
          icon: "",
        };
    }
  };

  const viewDetailOrders = async (record) => {
    const res = await store
      .dispatch("ecomOrder/fetchOrderById", {
        id: record.id,
      })
      .then((response) => {
        showDetailOrder.value = true;
        recordDetail.value = { ...response.data };
        store.commit("orders/updateViewDetail", recordDetail.value);
        return recordDetail.value;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return null;
      });
    return res;
  };

  const updateStatusOrder = (ordersId, params) => {
    store
      .dispatch("orders/updateStatusOrder", params)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_change_status_order_successfully"),
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_an_error_occurred_while_updating"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        viewDetailOrders({ id: ordersId });
      });
  };

  const uploadFileDefinition = async (
    file,
    order_item_attachment_id,
    ordersId
  ) => {
    const url = await uploadFile(file, order_item_attachment_id);
    if (!url) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_an_error_occurred_while_updating"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      return;
    }
    const params = {
      order_item_attachment_id,
      url,
    };

    store
      .dispatch("orders/updateAttachmentResult", {
        params,
        callback: () => viewDetailOrders({ id: ordersId }),
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_upload_file_successfully"),
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_an_error_occurred_while_uploading"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const uploadFile = async (file, order_item_attachment_id) => {
    const params = {
      file,
      FolderTarget: `attachmentOrderItem`,
      IdTarget: order_item_attachment_id,
    };
    const formData = new FormData();

    for (const key in params) {
      formData.append(key, params[key]);
    }

    const res = await Vue.prototype.$rf
      .getRequest("CommonRequest")
      .uploadFileTenant(formData);
    return res.data.fileUrl;
  };

  const timeout = ref(null);

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      followMode,
      outOfStockFilter,
      activeFilter,
      statusFilter,
      paymentStatusFilter,
      fromDate,
      toDate,
    ],
    () => {
      showTable.value = true;
      clearTimeout(timeout);
      timeout.value = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 2000);
    }
  );

  return {
    timeout,
    showTable,
    fetchOrdersList,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    searchQuery,
    followMode,
    sortBy,
    isSortDirDesc,
    refOrdersTable,
    statusFilterOptions,
    statusFilter,
    paymentStatusFilter,
    paymentStatusFilterOptions,
    refetchData,
    allOpenRows,
    ORDERS_STATUS,
    getOrderStatus,
    viewDetailOrders,
    recordDetail,
    showDetailOrder,
    updateStatusOrder,
    uploadFileDefinition,
    fromDate,
    toDate,
    isLoading,
    listOrders,
    // Extra
  };
}
